<!-- 考试成绩和排名 -->
<template>
  <div class="main">
    <div class="main-title"><span>考试成绩和排名</span></div>
    <div class="main-parts-wrapper">
      <div class="main-part">
        <div class="part-title">考试成绩和排名</div>
        <div>
          <table
            class="lj-table lj-table-bordered lj-table-centered lj-table-radius lj-table-striped lj-table-hover"
          >
            <thead>
              <tr>
                <th class="lj-text-middle">科目</th>
                <th class="lj-text-middle">得分</th>
                <th class="lj-text-middle">市排名</th>
                <th class="lj-text-middle">区县排名</th>
                <th class="lj-text-middle">校排名</th>
                <th class="lj-text-middle">年级排名</th>
                <th class="lj-text-middle">班级排名</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in majorsData" :key="index">
                <td>
                  {{ getChineseName(item.subject) }}
                </td>
                <td>
                  {{ item.score }}
                </td>
                <td>
                  {{ item.cityrank }}
                </td>
                <td class="td-text-left">
                  {{ item.countyrank }}
                </td>
                <td>
                  {{ item.schoolrank }}
                </td>
                <td>
                  {{ item.graderank }}
                </td>
                <td>
                  {{ item.classrank }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="main-buttons-wrapper">
      <a
        class="lj-btn lj-btn-default lj-btn-block main-button"
        @click="jumpPage('reportCenter')"
        >返回目录</a
      >
    </div>
  </div>
</template>

<script>
import './../../../Public/utils/chartjs/Chart'
import './../../../Public/utils/highcharts/highcharts'
import { getexamgrades } from './../../../api/report'
import {getEname } from './../../../utils/getEname.js'
export default {
  data: function () {
    return {
      majorsData: []
    }
  },
  computed: {
    isMobile: function () {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    }
  },
  created: function () {
    getexamgrades({}).then(res => {
      console.log(res)
      this.majorsData = res.data
    })
  },
  methods: {
    jumpPage: function (dest) {
      dest === '/'
        ? this.$router.replace({ path: dest })
        : this.$router.push({ path: dest })
    },
    generateTable: function (obj) {
      obj = [
        {
          subject: '',
          score: '',
          cityrank: '',
          countyrank: '',
          schoolrank: '',
          graderank: '',
          classrank: ''
        }
      ]
    },
    getChineseName:function(name){
      return getEname(name)
    }
  }
}
</script>

<style lang="less">
@import url('./../../../Public/resources/css/templates/home/major/std_result4.min.css');
.main-title {
  padding: 0;
}

@media only screen and (max-width: 480px) {
  .main {
    padding: 1.6rem 0;
  }

  .main .main-parts-wrapper {
    padding: 1rem 0;
  }
}
</style>
