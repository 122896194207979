/**
 * 
 * @param {name} 科目的英语名 
 * @returns 科目的中文名
 */
export function getEname (name) {
  var ename
  switch (name) {
    case 'history':
      ename = '历史'
      break
    case 'biology':
      ename = '生物'
      break
    case 'physics':
      ename = '物理'
      break
    case 'math':
      ename = '数学'
      break
    case 'chinese':
      ename = '语文'
      break
    case 'english':
      ename = '英语'
      break
    case 'politics':
      ename = '政治'
      break
    case 'chemistry':
      ename = '化学'
      break
    case 'geography':
      ename = '地理'
      break
    default:
      ename = ''
  }
  return ename
}
